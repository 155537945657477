import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";

interface Skill {
  name: string;
  percentage: number;
}

const SkillsSection: React.FC = () => {
  const skills: Skill[] = [
    { name: "React", percentage: 99 },
    { name: "Django", percentage: 99 },
    { name: "Docker", percentage: 90 },
    { name: "Typescript", percentage: 90 },
    { name: "Flutter", percentage: 70 },
    { name: "AWS / GCP", percentage: 55 },
  ];

  const sectionRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          setKey((prevKey) => prevKey + 1);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className="container mx-auto pl-7 pr-4 lg:px-4 py-16 flex flex-col md:flex-row font-poppins"
    >
      <div className="w-full md:w-1/2 pr-8 mb-8 md:mb-0">
        <h2 className="text-3xl font-bold mb-6 text-[#0f99b8]">
          My Technical Skills
        </h2>
        <p className="mb-4 text-[#FFFAFA] text-base lg:text-lg md:text-xl text-left">
          As a software engineer, I've developed a diverse skill set across
          various technologies and platforms, including expertise in multiple
          programming languages, both frontend and backend development, and
          proficiency in a range of frameworks. My passion for continuous
          learning drives me to stay updated with the latest industry trends and
          advancements.{" "}
        </p>
      </div>

      <div className="w-full md:w-1/2">
        <div className="text-[#016e86] text-md text-left font-semibold underline mb-5">
          Top Skills
        </div>
        {skills.map((skill, index) => (
          <div key={`${skill.name}-${key}`} className="mb-4">
            <div className="flex justify-between mb-2">
              <span className="text-[#FFFAFA] font-medium text-sm">
                {skill.name}
              </span>
            </div>
            <div className="w-full bg-[rgba(219,235,248,0.5)] rounded-full h-4 overflow-hidden">
              {isVisible && (
                <motion.div
                  key={`${skill.name}-animation-${key}`}
                  initial={{ width: 0 }}
                  animate={{ width: `${skill.percentage}%` }}
                  transition={{
                    duration: 1,
                    delay: index * 0.2,
                    type: "spring",
                    stiffness: 50,
                  }}
                  className="h-full bg-[#0f99b8] rounded-full"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;
