import React, { useEffect, useRef, useState } from "react";
import profileImage from "../assets/mypic.jpg";

function About() {
  const text6 =
    "With over 2 years of experience in software development, I have had the privilege to work on a diverse range of projects, including AI-driven applications like OpsSafety, an AI-based safety violation detection app. Beyond this, I have contributed to several other AI-related solutions, helping create innovative products that leverage technology to solve real-world challenges. These experiences have deepened my expertise and passion for developing impactful software.";
  const text1 = " Hey, I'm a dedicated";
  const text2 = "Software Engineer";
  const text3 =
    "with a strong passion for crafting innovative and efficient software solutions. I hold a";
  const text4 = " Master's degree in Computer Applications (MCA)";
  const text5 =
    ", which has equipped me with a solid foundation in computer science and software development. My expertise spans backend development, web technologies, and databases, allowing me to create user-focused and high-performing applications. I thrive on exploring new tools and frameworks to enhance my skills and deliver impactful solutions.";

  const delay = 5;

  const [currentText1, setCurrentText1] = useState("");
  const [currentText2, setCurrentText2] = useState("");
  const [currentText3, setCurrentText3] = useState("");
  const [currentText4, setCurrentText4] = useState("");
  const [currentText5, setCurrentText5] = useState("");
  const [currentText6, setCurrentText6] = useState("");

  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [currentIndex3, setCurrentIndex3] = useState(0);
  const [currentIndex4, setCurrentIndex4] = useState(0);
  const [currentIndex5, setCurrentIndex5] = useState(0);
  const [currentIndex6, setCurrentIndex6] = useState(0);

  const [isVisible, setIsVisible] = useState(false);
  const paragraphRef = useRef(null);

  // Typing logic for each text sequentially
  useEffect(() => {
    if (isVisible) {
      if (currentIndex1 < text1.length) {
        const timeout = setTimeout(() => {
          setCurrentText1((prev) => prev + text1[currentIndex1]);
          setCurrentIndex1((prev) => prev + 1);
        }, delay);
        return () => clearTimeout(timeout);
      } else if (currentIndex2 < text2.length) {
        const timeout = setTimeout(() => {
          setCurrentText2((prev) => prev + text2[currentIndex2]);
          setCurrentIndex2((prev) => prev + 1);
        }, delay);
        return () => clearTimeout(timeout);
      } else if (currentIndex3 < text3.length) {
        const timeout = setTimeout(() => {
          setCurrentText3((prev) => prev + text3[currentIndex3]);
          setCurrentIndex3((prev) => prev + 1);
        }, delay);
        return () => clearTimeout(timeout);
      } else if (currentIndex4 < text4.length) {
        const timeout = setTimeout(() => {
          setCurrentText4((prev) => prev + text4[currentIndex4]);
          setCurrentIndex4((prev) => prev + 1);
        }, delay);
        return () => clearTimeout(timeout);
      } else if (currentIndex5 < text5.length) {
        const timeout = setTimeout(() => {
          setCurrentText5((prev) => prev + text5[currentIndex5]);
          setCurrentIndex5((prev) => prev + 1);
        }, delay);
        return () => clearTimeout(timeout);
      } else if (currentIndex6 < text6.length) {
        const timeout = setTimeout(() => {
          setCurrentText6((prev) => prev + text6[currentIndex6]);
          setCurrentIndex6((prev) => prev + 1);
        }, delay);
        return () => clearTimeout(timeout);
      }
    }
  }, [
    currentIndex1,
    currentIndex2,
    currentIndex3,
    currentIndex4,
    currentIndex5,
    currentIndex6,
    isVisible,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    delay,
  ]);

  // Intersection Observer Logic
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          setCurrentText1("");
          setCurrentIndex1(0);
          setCurrentText2("");
          setCurrentIndex2(0);
          setCurrentText3("");
          setCurrentIndex3(0);
          setCurrentText4("");
          setCurrentIndex4(0);
          setCurrentText5("");
          setCurrentIndex5(0);
          setCurrentText6("");
          setCurrentIndex6(0);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.5 }
    );

    if (paragraphRef.current) {
      observer.observe(paragraphRef.current);
    }

    return () => {
      if (paragraphRef.current) {
        observer.unobserve(paragraphRef.current);
      }
    };
  }, []);

  return (
    <div
      id="about-me"
      className="w-full min-h-screen  border-0 relative lg:pl-20 lg:pr-4 bg-gradient-to-b from-[#070508] via-[#080609] to-[#09090b] ..."
    >
      <div className="w-full flex items-center justify-center text-[#0f99b8] pt-16">
        <div className="text-2xl lg:text-[2.5vw] font-semibold">About Me</div>
      </div>
      <div className=" mt-10 lg:mt-20 w-full lg:min-h-[25rem] p-2  flex flex-col md:flex-row lg:flex-row gap-2">
        {/* Profile Image */}
        <div className=" w-full md:w-2/4 lg:w-1/4 flex items-center justify-center p-4">
          <div className="flex justify-center items-center w-64 h-64 bg-[#016e86] rounded-full p-1">
            <img
              src={profileImage}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
        </div>
        <div className=" w-full md:w-2/4 lg:w-3/4 flex items-center justify-start text-[#FFFAFA] text-2xl p-5 text-left lg:pr-20">
          <div ref={paragraphRef}>
            <p className="text-base lg:text-lg md:text-xl font-light leading-relaxed font-poppins">
              {currentText1}
              <span className="text-[#3adbff] font-medium">
                {" "}
                {currentText2}
              </span>{" "}
              {currentText3}
              <span className="text-[#3adbff] font-medium">
                {" "}
                {currentText4}
              </span>
              {currentText5}
            </p>

            <p className="text-base lg:text-lg md:text-xl font-light leading-relaxed font-poppins mt-10">
              {currentText6}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
