import { useEffect, useRef, useState } from "react";
import banner from "../assets/banner.jpg";
import SkillsSection from "../components/Skills";
import About from "./About";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

function Home() {
  const aboutRef = useRef<HTMLDivElement | null>(null);
  const skillRef = useRef<HTMLDivElement | null>(null);

  const [scrolledToAbout, setScrolledToAbout] = useState(false);
  const [scrolledToSkills, setScrolledToSkills] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = banner;
    img.onload = () => {
      setIsImageLoaded(true);
    };
    img.onerror = () => {
      console.error("Failed to load banner image");
      setIsImageLoaded(true);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const banner = document.getElementById("banner");
      const about = document.getElementById("about");
      if (
        banner &&
        banner.getBoundingClientRect().bottom <= window.innerHeight &&
        !scrolledToAbout
      ) {
        aboutRef.current?.scrollIntoView({ behavior: "smooth" });
        setScrolledToAbout(true);
      }

      if (window.scrollY === 0) {
        setScrolledToAbout(false);
      }

      if (
        about &&
        about.getBoundingClientRect().bottom <= window.innerHeight &&
        !scrolledToSkills
      ) {
        skillRef.current?.scrollIntoView({ behavior: "smooth" });
        setScrolledToSkills(true);
      }

      if (window.scrollY === 0) {
        setScrolledToSkills(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrolledToAbout, scrolledToSkills]);

  return (
    <div>
      {/* Loader */}
      {!isImageLoaded ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#070508]">
          <div className="animate-pulse">
            <div className="w-24 h-24 border-4 border-t-[#0f99b8] border-r-[#016e86] border-b-transparent border-l-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-[#0f99b8] text-center">Loading...</p>
          </div>
        </div>
      ) : null}
      {/* Banner section */}

      <div
        id="banner"
        className="w-full h-screen border-0 relative bg-cover bg-center md:bg-center lg:bg-top"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="w-full h-full flex items-center justify-start pl-16 md:pl-30 lg:pl-40 pt-10 ">
          <div className="lg:w-[40%] sm:w-[50%] w-[80%] h-[60%]">
            <div className="w-full h-full flex flex-col items-center justify-center gap-4">
              <h1 className="text-[#016e86] text-2xl md:text-3xl lg:text-3xl font-semibold">
                Hello world !
              </h1>
              <h1 className="text-[#0f99b8] text-3xl md:text-4xl lg:text-5xl font-bold">
                I'm Akhil Varghese
              </h1>
              <p className="text-[rgba(219,235,248,0.5)] text-1xl md:text-2xl lg:text-2xl">
                Full-Stack Software Engineer
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About section */}

      <div ref={aboutRef} id="about">
        <About />
      </div>

      {/* Skills */}
      <div
        className="w-full min-h-screen lg:pl-8 lg:pr-4 bg-gradient-to-b from-[#09090b] via-[#080609] to-[#070508] ..."
        ref={skillRef}
      >
        <SkillsSection />
      </div>
    </div>
  );
}

export default Home;
