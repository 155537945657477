import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedinIn,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Footer: React.FC = () => {
  return (
    <footer className="fixed bottom-0 left-0 w-full bg-neutral-900 py-4 z-50">
      <div className="container mx-auto flex justify-center items-center space-x-6">
        <a
          href="https://www.linkedin.com/in/akhil-varghese-474639222/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-opacity-45 hover:text-gray-300 transition-colors duration-300"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>

        <a
          href="https://github.com/mrpulikkan"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-opacity-45 hover:text-gray-300 transition-colors duration-300"
        >
          <FontAwesomeIcon icon={faGithub} />
        </a>

        <a
          href="https://x.com/mrpulikkan"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white text-opacity-45 hover:text-gray-300 transition-colors duration-300"
        >
          <FontAwesomeIcon icon={faXTwitter} />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
