import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faGraduationCap,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";

const ProfileSection: React.FC = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsImageLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const experiences = [
    {
      company: "Pumex Infotech Pvt ltd",
      position: "Software Engineer",
      duration: "Nov 2022 - Present",
      description: [
        "Designed, developed, and maintained scalable web applications.",
        "Worked with various front-end technologies, including React and Flutter.",
        "Learned and worked with various back-end technologies, such as Django REST framework.",
        "Implemented containerization solutions using Docker.",
        "Learned and implemented event-streaming solutions using Kafka.",
        "Worked closely with designers and back-end developers to ensure seamless integration and functionality.",
      ],
    },
    {
      company: "CIAL",
      position: "CRM Executive & Web administration",
      duration: "Sep 2022 - Nov 2022",
      description: [
        "Managed and optimized CRM tools to track and improve customer interactions.",
        "Coordinated with sales and marketing teams to ensure seamless lead tracking and follow-up.",
        "Administered the company's e-commerce website, ensuring uptime, functionality, and regular content updates.",
      ],
    },
  ];

  const projects = [
    {
      name: "OpsSafety",
      technologies: [
        "React",
        "Python",
        "Docker",
        "Kafka",
        "OpenCV",
        "FFMPEG",
        "Tailwind cSS",
      ],
      description:
        "An AI platform for real-time safety violation detection in high-risk industries using. ",
      githubLink: "https://github.com/example/project-management",
      liveLink: "https://project-management.example.com",
    },
    {
      name: "Mangoapeal",
      technologies: [
        "Django",
        "Bootstrap",
        "MySql",
        "Javascript",
        "HTML",
        "CSS",
      ],
      description:
        "An e-commerce web application for selling handcrafted products made from recycled plastic materials, aimed at reducing and reusing plastic waste.",
      githubLink: "https://github.com/example/ecommerce-platform",
    },
    {
      name: "Money Expense Tracker",
      technologies: [
        "React",
        "Node JS",
        "Mongo DB",
        "Express",
        "CSS",
        "REST API",
      ],
      description:
        "A MERN stack web application for accurate and user-friendly expense tracking.",
      githubLink: "https://github.com/example/ecommerce-platform",
    },
    {
      name: "OmniScop",
      technologies: ["Flutter", "Flutter flow"],
      description:
        " Built a data analysis tool leveraging LLMs for generating narrative reports based on analytical questions, using Flutter for UI development.",
      githubLink: "https://github.com/example/ecommerce-platform",
    },
  ];

  const education = [
    {
      institution: "Mahatma Gandhi University, Kottayam",
      degree: "Master of Computer Application",
      graduationYear: "2022",
      highlights: ["Graduated with SCGPA: 8.3/10.0."],
    },
    {
      institution: "Mahatma Gandhi University, Kottayam",
      degree: "Bachelor of Computer Application",
      graduationYear: "2020",
      highlights: ["Graduated with SCGPA: 7.3/10.0."],
    },
  ];

  return (
    <>
      {/* Loader */}
      {!isImageLoaded ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#070508]">
          <div className="animate-pulse">
            <div className="w-24 h-24 border-4 border-t-[#0f99b8] border-r-[#016e86] border-b-transparent border-l-transparent rounded-full animate-spin"></div>
            <p className="mt-4 text-[#0f99b8] text-center">Loading...</p>
          </div>
        </div>
      ) : (
        <div className="w-full mx-auto mb-10 px-4 py-12 lg:px-28 bg-gradient-to-b from-[#070508] via-[#080609] to-[#09090b] ... text-gray-300 font-poppins">
          {/* Experience Section */}
          <section className="mb-12 mt-20">
            <div className="flex items-center mb-6">
              <FontAwesomeIcon
                icon={faBriefcase}
                className="mr-3 text-blue-400"
                size="2x"
              />
              <h2 className="text-2xl font-semibold text-gray-100">
                My Experience
              </h2>
            </div>
            <div className="space-y-6">
              {experiences.map((exp, index) => (
                <div
                  key={index}
                  className="bg-[#1a1a1d] lg:min-h-52 rounded-lg  hover:bg-[#1f1f23] flex flex-col md:flex-row lg:flex-row "
                >
                  <div className="basis-1/4 min-h-full p-6 rounded-md bg-[#016e86] text-left">
                    <h3 className="text-xl font-bold text-gray-100">
                      {exp.position}
                    </h3>
                    <span className="text-gray-400 text-xl md:text-lg">
                      {exp.company}
                    </span>
                    <span className="block md:hidden lg:hidden text-sm text-gray-400">
                      {exp.duration}
                    </span>
                  </div>

                  <div className="basis-3/4 flex justify-between p-6 ">
                    <div className="basis-4/5">
                      <ul className="list-disc list-inside text-gray-400 text-left">
                        {exp.description.map((item, idx) => (
                          <li key={idx} className="mb-1">
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="min-h-full  basis-1/5 hidden md:block lg:block">
                      {exp.duration}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Projects Section */}
          <section className="mb-12">
            <div className="flex items-center mb-6">
              <FontAwesomeIcon
                icon={faFolder}
                className="mr-3 text-green-400"
                size="2x"
              />
              <h2 className="text-3xl font-semibold text-gray-100">
                Notable Projects
              </h2>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              {projects.map((project, index) => (
                <div
                  key={index}
                  className="bg-[#1a1a1d]  rounded-lg p-6 hover:bg-[#1f1f23]"
                >
                  <h3 className="text-xl font-bold text-gray-100 mb-3">
                    {project.name}
                  </h3>
                  <div className="flex flex-wrap gap-2 mb-3 mt-5">
                    {project.technologies.map((tech, techIndex) => (
                      <span
                        key={techIndex}
                        className="bg-[#016e86] text-white text-xs px-3 py-1 rounded-full"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                  <p className="text-gray-400 mb-4 text-left">
                    {project.description}
                  </p>
                  <div className="space-x-3 hidden">
                    {project.githubLink && (
                      <a
                        href={project.githubLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-300 underline"
                      >
                        GitHub
                      </a>
                    )}
                    {project.liveLink && (
                      <a
                        href={project.liveLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-300 underline"
                      >
                        Live Demo
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </section>

          {/* Education Section */}
          <section>
            <div className="flex items-center mb-6">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="mr-3 text-purple-400"
                size="2x"
              />
              <h2 className="text-3xl font-semibold text-gray-100">
                Education
              </h2>
            </div>
            <div className="space-y-6">
              {education.map((edu, index) => (
                <div
                  key={index}
                  className="bg-[#1a1a1d] shadow-md rounded-lg hover:shadow-lg transition-shadow mb-3 pb-5"
                >
                  <div className="flex justify-between items-center ">
                    <div className="lg:min-w-3/5 lg:h-12 pb-0.5 pr-1 bg-[#016e86] border-b-2 border-[#1cd5ff] rounded-r-3xl  flex items-center justify-center">
                      <h3 className="lg:text-xl font-bold text-gray-100 text-left p-2 ">
                        {edu.degree}
                      </h3>
                    </div>

                    <span className="text-gray-400 text-sm px-6">
                      {edu.graduationYear}
                    </span>
                  </div>
                  <p className="text-white mt-2 mb-2 text-left md:text-center font-medium px-10 ">
                    {edu.institution}
                  </p>
                  {edu.highlights && (
                    <ul className="list-disc list-inside text-gray-400 text-left md:text-center px-10 ">
                      {edu.highlights.map((highlight, idx) => (
                        <li key={idx} className="mb-1">
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ProfileSection;
