import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AWS from "aws-sdk";

const Navbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const [aboutLinkTo, setAboutLinkTo] = useState<string>("#about");

  const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? "";
  const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? "";

  AWS.config.update({
    region: "ap-south-1",
    credentials: new AWS.Credentials(accessKeyId, secretAccessKey),
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    if (location.pathname.endsWith("/")) {
      setAboutLinkTo("#about");
    } else {
      setAboutLinkTo("/");
    }
  }, [location.pathname]);

  const downloadResume = () => {
    console.log("downloading...");
    const params = {
      Bucket: "mrpulikkan-portfolio",
      Key: "Akhil_CV_2024.pdf",
      Expires: 60 * 5,
      ResponseContentDisposition: 'inline; filename="Akhil_Varghese_CV.pdf"',
      ResponseContentType: "application/pdf",
    };

    s3.getSignedUrl("getObject", params, (err, url) => {
      if (err) {
        console.error("Error generating signed URL:", err);
        return;
      }

      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Akhil_Varghese_CV.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Download error:", error);
        });
    });
  };

  const navItems = [
    { name: "About", href: aboutLinkTo },
    { name: "Profile", href: "profile" },
    { name: "Contact Me", href: "contact-me" },
    { name: "Download CV", href: "#resume", onClick: downloadResume },
  ];

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-transparent backdrop-blur-lg font-poppins">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        <div className="text-white font-bold text-xl cursor-pointer" onClick={()=>{navigate('/')}}>Portfolio</div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex space-x-6">
          {navItems.map((item) =>
            item.onClick ? (
              <button
                key={item.name}
                onClick={item.onClick}
                className="text-white hover:text-gray-300 transition-colors duration-300"
              >
                {item.name}
              </button>
            ) : (
              <a
                key={item.name}
                href={item.href}
                className="text-white hover:text-gray-300 transition-colors duration-300"
              >
                {item.name}
              </a>
            )
          )}
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="text-white focus:outline-none"
          >
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        {isOpen && (
          <div className="absolute top-full left-0 w-full bg-black/70 backdrop-blur-lg md:hidden">
            <div className="container mx-auto px-4 flex flex-col items-start py-4 space-y-4">
              {navItems.map((item) =>
                item.onClick ? (
                  <button
                    key={item.name}
                    onClick={() => {
                      item.onClick();
                      setIsOpen(false);
                    }}
                    className="text-white hover:text-gray-300 transition-colors duration-300 text-left w-full"
                  >
                    {item.name}
                  </button>
                ) : (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-white hover:text-gray-300 transition-colors duration-300 text-left w-full"
                    onClick={() => setIsOpen(false)}
                  >
                    {item.name}
                  </a>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
